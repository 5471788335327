.title_container {
    height: 10%;
    display: flex;
    justify-content: center;
    margin-top: 20px
}

.input_container {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    height: 20%
}

.overall_container {
    height: 100%
}

.text_container {
    height: 60%;
    margin-left: 5%;
    width: 90%
}

.overall_container button {
    background: var(--main-color);
    color: white;
    font-size: 1.25rem;
    border-radius: 10px;
    padding: 0.5rem 1.5rem;
    border: none;
    height: 40px;
    font-weight: 700;
    border-radius: 10px
}

.overall_container button:hover {
    cursor: pointer;
    background: var(--main-color-light)
}

.error {
    color: red;
    width: 100%;
    text-align: center
}