.main_container {
    width: 100%;
    height: 100%
}

.main_container h3 {
    margin-top: 20px;
    text-align:center;
    margin-bottom: 0;
    height: 20%;
}

.button_container {
    width: 60%;
    margin-left: 20%;
    display: flex;
    justify-content: space-evenly;
    align-items: end;
    height: 60%;
}


.first_question_container {
    height: 35%;
    margin-top: 20px
}

.button_container button {
    width: 100%;
    margin-left: 2%;
    margin-right: 2%;
    border-radius: 10px;
    font-size: 1.3rem;
    min-height: 60px;
    max-height: 60px;
    font-weight: 700;
    transform: translateX(-5px) translateY(-5px);
    transition: all 0.2s linear;
}

.button_container button.unpressed:hover {
    background: var(--full-legacy-light);
    border: 1.5px solid var(--full-legacy);
    color: var(--full-legacy);
    cursor: pointer;
    transform: translateX(-5px) translateY(-5px);
    font-weight: 700;
    transform: translateX(0) translateY(0);
    
}

 .button_container button.pressed {
    background: var(--main-color-light);
    border: 1.5px solid var(--main-coor);
    color: var(--main-color);
    transform: translateX(0) translateY(0);
}

 .button_container button.unpressed {
    background: var(--light-grey);
    border: 1.5px solid black;
    color: black;
    font-weight: 700;
 }

 .input_container {
    width: 40%;
    margin-left: 30%;
    transition: all 0.3s linear;
    height: 30%;
    padding-top: 0.1px
 }

 .added_pension_input {
    transform: translateX(0);
    opacity: 1;
    transition: all 0.3s linear
 }

 .added_pension_input.hidden {
    transform: translatey(500px);
    opacity: 0;
 }


 .explain_container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top:20px
}

.explain_container button {
    background: var(--main-color);
    color: white;
    font-size: 1.25rem;
    border-radius: 10px;
    padding: 0.5rem 1.5rem;
    border: none;
    height: 60px;
    font-weight: 700;
    border-radius: 10px
}

.explain_container button:hover {
    cursor: pointer;
    background: var(--main-color-light)
}

p.error {
    width: 100%;
    text-align: center;
    color: red;
    margin-top: 20px;
}