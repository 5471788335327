.main_container {

    width: 100%; /* Set the width of the grid container */
    height: 25vh;
    margin-left: 0%;
    background: var(--main-color);
    border-radius: 10px;
    --pad: 5px;
    color: white;
    min-height: 350px;
}

.title_bar {
    height: 20%;
    margin: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

.title_bar button {
    position: absolute;
    right: 20px;
    background: var(--light-grey);
    color: black;
    font-weight: 700;
    border: 2px solid black;
    border-radius: 10px;
    width: 5rem;
    height: 2rem;
    box-shadow: 5px 5px black;
    transition: all 0.1s linear

}

.title_bar button:hover {
    transform: translate(5px, 5px);
    box-shadow: 0px 0px black;
    cursor: pointer;
}

.grid_container {
    display: grid;
    grid-template-columns: repeat(7, 1fr); /* Creates 6 columns with equal fraction of available space */
    grid-template-rows: repeat(2, 1fr); /* Creates 2 rows, each taking an equal fraction of available vertical space */
    column-gap: 5px; /* Space between columns */
    row-gap: 5px; /* Space between rows */
    justify-items: center; /* Center grid items along the horizontal axis */
    align-items: center; /* Center grid items along the vertical axis */
    width: calc(100% - 2 * var(--pad));
    padding-left: var(--pad);
    height: calc(80% - 1 * var(--pad));
}

.grid_item {
    /* Styling for individual grid items */
    width: 100%; /* Use the full width available in each grid cell */
    height: 100%; /* Use the full height available in each grid cell */
    background-color: #f0f0f0; /* Background color for visualization */
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    flex-direction: column;
    transition: transform 0.3s linear, background-color 0.3s linear;
    color: black;
    font-weight: 700
}

.grid_item.valid {
    transform: rotateX(360deg);
    background-color: var(--main-color-light)
}

.grid_item.error {
    transform: rotateX(720deg);
    background-color: coral;
}

.grid_item.being_answered {
    background-color: lemonchiffon;
    transform: rotateX(1080deg)
}


.grid_item button > img {
    width: 20px;
    height: 20px;
    bottom: 0px;
    right: -30px;
    position: absolute
}

.big_crayon {
    transform: scale(1.1)
}

.grid_item button {
    position: relative;
    background: var(--main-color);
    color: white;
    font-size: 1rem;
    border-radius: 10px;
    transition: transform 0.2s linear
}

.grid_item button:hover {
    transform: scale(1.1);
    cursor: pointer
}

.icon_container {
    display: flex;
    align-items: center
}

.tick, .cross, .big_crayon {
    width: 20px; 
    height: 20px;;
    margin-left: 20px
}

.title_container {
    display: flex;
    justify-content: space-evenly
}

.redundant {
    width: 100%;
    height: 100%
}

.redundant p {
    width: 100%;
    height: 100%;
    background: var(--main-color);
    color: white;
    margin: 0;
    border-radius: 10px;
    font-size: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center
}