.main_container {
    width : 100%;
    margin-left: 0%;
    height: calc(50vh + var(--extra-height));
    background: var(--very-light-grey);
    border-radius: 10px;
    min-height: calc(400px + var(--extra-height));
}

.question_slider {
    margin-top: 20px;
}

.slider {
    height: 100%;
    width: 1400%;
    display: flex;
    flex-direction: row;
    transition: transform 0.3s linear
}

.slider_container {
    width: 100%;
    overflow: hidden;
    height:100%
}

.slide {
    width : 100%;
    height: 100%
}

.submit_container {
    display: flex;
    width: 100%;
    height: 10vh;
    justify-content: space-evenly;
    margin-top: 20px
}



.submit_container button {
    background: var(--main-color);
    color: white;
    font-size: 1.25rem;
    border-radius: 10px;
    padding: 0.5rem 1.5rem;
    border: none;
    height: 60px;
    font-weight: 700;
    border-radius: 10px;
    width: 20%;
    transition: all 0.2s linear
}

.submit_container button:hover {
    cursor: pointer;
    background: var(--main-color-light)
}


button.hidden_button {
    opacity: 0;
    transform: rotateY(360deg)
}

.dummy_button {
    opacity: 0;
    visibility: hidden
}


.calculate_button_revealed > button:first-child, .calculate_button_revealed > button:last-child {
    background: grey;
    transform: scale(0.8)
}

.calculate_button_revealed > button:nth-child(2) {
    box-shadow: 10px 10px black;
    transition: all 0.2s linear;
    transform: translate(-10px, -10px) scale(1.1);
}

.calculate_button_revealed.calculate_button_revealed > button:nth-child(2):hover {
    box-shadow: 0px 0px black;
    transform: translate(-0px, -0px) scale(1);
}