.main_container {
    height: 100%
}

.title_container {
    height: 10%;
    display: flex;
    justify-content: center;
    margin-top: 20px
}

.fs_container {
    height: 45%
}

.explain_container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: calc(40% - 20px)
}

.explain_container button {
    background: var(--main-color);
    color: white;
    font-size: 1.25rem;
    border-radius: 10px;
    padding: 0.5rem 1.5rem;
    border: none;
    height: 60px;
    font-weight: 700;
    border-radius: 10px
}

.explain_container button:hover {
    cursor: pointer;
    background: var(--main-color-light)
}