.options_container {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    height: calc(50% - 20px);
    width: 100%
}

.options_container button {
    width: 100%;
    margin-left: 2%;
    margin-right: 2%;
    border-radius: 10px;
    font-size: 1.3rem;
    min-height: 60px;
    max-height: 60px;
    font-weight: 700;
    transform: translateX(-5px) translateY(-5px);
    transition: all 0.2s linear;
}

.options_container button.unpressed:hover {
    background: var(--full-legacy-light);
    border: 1.5px solid var(--full-legacy);
    color: var(--full-legacy);
    cursor: pointer;
    transform: translateX(-5px) translateY(-5px);
    font-weight: 700;
    transform: translateX(0) translateY(0);
    
}

 .options_container button.pressed {
    background: var(--main-color-light);
    border: 1.5px solid var(--main-coor);
    color: var(--main-color);
    transform: translateX(0) translateY(0);
}

 .options_container button.unpressed {
    background: var(--light-grey);
    border: 1.5px solid black;
    color: black;
    font-weight: 700;
 }

.overall_container {
    width: 100%;
    height: calc(100% - 0.1px);
    padding-top: 0.1px /* to prevent margin collapse*/
}

.overall_container h3 {
    height: 10%;
    margin-top: 20px;
    text-align:center;
    margin-bottom: 0
}

.explain_container {
    display: flex;
    align-items: center;
    justify-content: center
}

.explain_container button {
    background: var(--main-color);
    color: white;
    font-size: 1.25rem;
    border-radius: 10px;
    padding: 0.5rem 1.5rem;
    border: none;
    height: 60px;
    font-weight: 700;
    border-radius: 10px
}

.explain_container button:hover {
    cursor: pointer;
    background: var(--main-color-light)
}

.body_text {
    width: 90%;
    margin-left: 5%;
    font-size:1.25rem;
}

@media (max-width: 1100px) {
    .body_text div p, .body_text div h2 {
        font-size:0.8rem;
    }
}

@media (min-width: 1100px) and (max-width: 1400px) {
    .body_text div p, .body_text div h2 {
        font-size:0.9rem;
    }
}

@media (min-width: 1400px) and (max-width: 1800px) {
    .body_text div p, .body_text div h2 {
        font-size: 1rem;
    }
}

.body_text div h2 {
    text-align:center;
    margin-top: -5px;
    margin-bottom:5px
}

.body_text div {
    background: lightgrey;
    padding: 10px;
    margin-bottom:-40px;
    
    border-radius: 10px;
    box-shadow: 0px 0px 2px 2px grey;
    transition:transform 0.2s linear

}

.body_text div:hover {
    transform: scale(1.1);
    cursor:pointer
}