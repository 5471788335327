.switch_container {
  margin-top: 1rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.switch_container :not(:last-child) {
  margin-right: 0.5rem;
}

.chart_container {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin-top: 2rem;
}

.toggle_title_and_button_container {
  display: flex;
  justify-content: center;
  width: 100%;
  align-items: center;
  margin-bottom: 10px;
  margin-top: 20px;
}

.chart {
  width: 50%;
  height: 26rem;
}

.joint_container {
  margin-top: 1rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: center;
}

.joint_container :first-child {
  margin-right: 0.5rem;
}

.chart2 {
  width: 66%;
  height: 26rem;
}

.LegendContainer {
  margin-top: 10px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100px;
  justify-content: center;
  width: 100%
}

.LegendSubContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.legendBox {
  width : 50%;
  display: flex;
  align-items: center;
  margin-bottom: 10px; /* space between two rows */
}

.legendBox:nth-child(2n - 1) {
  transform: translateX(20%)
}



.legendBoxInputs {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
}

.legendBox div {
  width: 20px;
  height: 20px;
  background-color: var(--value);
  margin-right: 10px;
  border-radius: 50%;
  margin-left: 80px;
}

.legendBox:nth-child(n+3) div {
  border-radius: 0
}

.legendBoxInputs div {
  width: 50%;
  height: 50px;
  background-color: var(--value);
  
  
  border: 1px solid black;
}

.toggle {
  transition: transform 0.3s linear;
  width: 100%;
}

.revealed_toggle {
  transform: scale(1);
}

.hidden_toggle {
  transform: scale(0);
}

.chart_and_settings_container {
  width: 100%;
  display: flex;
}

.chart_container {
  min-width: calc(33.3333%);
  height: 500px;
  display: flex;
    justify-content: stretch;
}

.chart_swiper {
  min-width : calc(300%);
  display: flex;
  flex-direction: row;
  transition: transform 0.5s ease-in-out
  
}

.swiper_container {
  max-width : calc(100% - 400px);
  overflow-x: hidden
}

.both_toggles_container {
  display: flex;
  flex-direction: column;
  width: 350px;
  margin-left: 50px;
  border: 2px solid black;
  border-radius: 10px;
  justify-content: space-around;
  align-items: center;
  min-height: 500px;
  position: relative;
  margin-top: 2rem;
  background: rgb(230,230,230)
}

.variable_based_view {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  position: relative;
}
.view_settings {
    width: 100%;
    text-align: center;
}

.first_toggle {
    width: 100%;
    background: transparent;
    display: flex;
    flex-direction: column;
    align-items: center
}

.first_toggle p {
  font-size: 20px;
  color : rgba(100,100,100);
  transition: all 0.4s linear;
}

.first_toggle p.toggle_active {
  color: black;
  transform: scale(1.1);
  font-weight: 900
}

.pr_legend {
  height: 20px;
  width: 20px;
  background: var(--value);
  margin-left: 20px;
  /* opacity: 0.2; */
  border: 1px solid black;
}

.view_settings_container {
  display: flex;
    --value: rgba(255, 99, 132, 0.10);
    align-items: center;
    justify-content: center;
}

.care_legacy_header {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

.variable_legend {
  width: calc(100% - 450px);
  height: 100px;
  margin-bottom: 10px;
  margin-top: 10px;
}

.variable_legend_body {
  display : flex;
  width: 100%;
  justify-content: space-evenly;
  padding-left: 5%;
}

.pr_variable_legend {
  background: var(--value);
  width : 80%;
  border: 1px solid black;
}

.fr_variable_legend {
  width : 80%;
}

.input_box {  
  width: 80%;  
  margin-left: 10%;
  border: 2px solid black; 
  margin-bottom: 30px;  
  margin-top: 10px;  
  padding-top: 20px;  
  padding-bottom: 45px;  
  background: rgb(230,230,230);  
  border-radius: 10px;  
  display: inline-block;  
}  

.input_titles {
  display : flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
}

.input_body {
  width: 100%;
  display: flex;

}

.legacy_inputs {
  width : 100%;
}

.care_inputs {
  width : 100%;
}

.added_pension {
  width : 100%;
  display : flex;
  justify-content:flex-start;
  align-items: center;
  margin-bottom: 10px;
}

.added_pension_toggle_container {
  width : 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 2;
  position: relative
}


.added_pension_toggle_container.forbidden_toggle:hover {
  cursor: not-allowed;
}

.added_pension_toggle_container.forbidden_toggle:hover * {
  cursor: not-allowed;
}

.added_pension_toggle_container.forbidden_toggle:hover button {
  cursor: pointer;
}

.added_pension_toggle_container p {
  padding : 10px;
  margin-bottom: 0;
}


.toggle_title_and_button_container button {
  width: 100px;
  height: 30px;
  color: black;
  border: 2px solid black;
  border-radius: 10px;
  cursor: pointer;
  background: white;
  transition: all 0.2s linear;
}

.toggle_title_and_button_container button:hover {
  transform: scale(1.03);
  box-shadow: 2px 2px 2px 2px lightgrey;
  background: var(--legacy-curtailed);
  color: white;
  font-weight: 500;
}

.table_explanation_container button {
  width: 200px;
  height: 50px;
  color: black;
  border: 2px solid black;
  border-radius: 10px;
  cursor: pointer;
  background: white;
  transition: all 0.2s linear;
}

.table_explanation_container button:hover {
  transform: scale(1.03);
  box-shadow: 2px 2px 2px 2px lightgrey;
  background: var(--legacy-curtailed);
  color: white;
  font-weight: 500;
}

.added_pension_toggle_container button {
  position: absolute;
  left:  calc(100% + 10px);
  color: black;
  background: white;
  border-radius: 10px;
  border: 2px solid black;
  transition: transform 0.1s linear;
  width: 200px;
  height: 40px;
  font-weight: 500;
  font-size: medium;
}

.added_pension_toggle_container button:nth-of-type(2) {
  top: 10%;
  width: 45%;
}

.chart_button {
  transition: transform 0.1s linear;
  position: absolute;
  left: 50%;
  color: rgb(104, 104, 104);
  background: transparent;
  border-radius: 10px;
  border: 2px solid gb(104, 104, 104);
  transition: transform 0.1s linear;
  width: 160px;
  top: 15px;
  z-index: 3
}
.added_pension_toggle_container button:hover, .chart_button:hover {
  color: white;
  border: none;
  transform: scale(1.03);
  cursor: pointer;
  background: var(--main-color);
  border-color: var(--main-color)
}



.added_pension h3 {
  flex: 2;
  padding-left: 30px;
}

.added_pension .precise {
  width: 40px;
  height: 40px;
  border : 4px solid var(--main-color);
  border-radius: 50%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 30px;
  margin-right: 15px;
  background: white;
  color: var(--main-color)
}



.precise_container {
  display: flex;
  margin-left : 20px;

}
/*
input[type=range] {
  -webkit-appearance: none;
  width: 200px
}

input[type=range]::-webkit-slider-thumb {
  -webkit-appearance: none;
  border: none;
  height: 16px;
  width: 16px;
  border-radius: 50%;
  background: var(--legacy-curtailed);
  margin-top: -4px;
  cursor: pointer;
}

input[type=range]::-moz-range-thumb {
  -webkit-appearance: none;
  border: none;
  height: 16px;
  width: 16px;
  border-radius: 50%;
  background: var(--legacy-curtailed);
  margin-top: -4px;
  cursor: pointer;
}

input[type=range]::-webkit-slider-runnable-track {
  -webkit-appearance: none;
  height: 5px;
  background: var(--main-color);
  border: none;
  border-radius: 3px;
}
*/
input[type="range"]:hover::-webkit-slider-thumb {
  opacity: 1;
}

input[type="range"] {
  -webkit-appearance: none;
  appearance: none;
  height: 1.1rem;
  border: 2px solid black;
  overflow: hidden;
  border-radius: 4px;
  outline: none;
  margin: 0;
  margin-right: 0.4rem;
  cursor: pointer;
  width: 300px;
}

 input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 18px;
  height: 1.1rem;
  box-shadow: -500px 0 0 500px var(--main-color);

  background: rgba(0, 0, 0, 1);
  opacity: 0.65;
}

.input_toggle {
  color : rgba(100,100,100, 0);
  transition: all 0.4s linear;
}

.input_toggle.input_toggle_active {
  color: black;
  transform: scale(1.2);
  font-weight: 600;
}


.slider_label {
  margin-top: 10px;
  margin-bottom: 0;
}

.income_legend_items {
  display: flex;
  flex-direction: row
}

.income_legend_items span {
  width: 20px;
  height: 20px;
  display: block;
  margin-right: 5px
}

.income_legend_items p {
  margin-left: 5px;
}

.income_container {
  display: flex;
  flex-direction: column;
  margin-left : 20px;
}

.income_container_container {
  margin-top: 10px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100px;
  justify-content: center;
  width: 100%
}

.income_chart_explainer {
  animation: rotater 0.5s ease-in-out
}

@keyframes rotater {
  0% {transform: scale(0) rotate(0deg)}
  100% {transform: scale(1) rotate(360deg)}
}

.view_button_container {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 100%;
  height: 100px;
  margin-top: 10px;
}

.view_button_container button {
  height: 80%;
  width: 28%;
  border: 2px solid black;
  color : black;
  border-radius: 10px;
  transition: all 0.2s linear;
  background: white;
}

.view_button_container button:hover {
  background: var(--legacy-curtailed);
  color : white;
  transform: scale(1.05);
  box-shadow: 3px 3px 3px 3px lightgrey;
  cursor: pointer;
  border: none;
}

.view_button_container button.selected {
  color: white;
  background: var(--main-color);
}

.both_header_containers > div > h1 {
  text-align: center;
}

.both_header_containers > div > div > h1 {
  text-align: center;
}

.full_retirement_box_wobbler1 {
  animation: wobble 0.5s linear;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

.full_retirement_box_wobbler2 {
  animation: wobble 0.5s linear;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}


.top_explainer {
  width: 80%;
    padding: 10px;
    background: white;
    margin: 20px 0 10px 10%;
}

.top_explainer p{
  font-size: 18px;
  padding-bottom: 20px;
  padding-top: 20px;
}

.border_line {
  border-bottom : 2px solid black
}

select:hover {
  cursor: pointer;
  transform: scale(1.1)
}

.table_button_container button {
  height: 50px;
}

.table_button_container {
  height: 150px;
  align-items: center;
}

.table_explanation_container {
  background: rgb(230,230,230);
  padding: 20px;
  border: 2px solid black;
  border-radius: 10px;
}

.partial_retirement_box {
  animation: fly_in 0.3s cubic-bezier(0.63, 0.05, 0.79, 1.35);
  animation-delay: 0.05s;
  animation-fill-mode: forwards;
  transform: translateX(-200%)
}

.full_retirement_box {
  animation: fly_in2 0.3s cubic-bezier(0.63, 0.05, 0.79, 1.35);
  animation-delay: 0.55s;
  animation-fill-mode: forwards;
  transform: scale(0) translateX(200%) rotate(0);

}

@keyframes fly_in {
  0% {transform: scale(0) translateX(-200%)}
  100% {transform: scale(1) translateX(0%)}
}

@keyframes fly_in2 {
  0% {transform: scale(0) translateX(200%)}
  100% {transform: scale(1) translateX(0%)}
}

.forbidden_toggle input {
  pointer-events: none;
}

.hs_table_explainer_container {
  display: flex
}

.hs_table_explainer_container p:first-child {
  width: 70%;
  text-align: left;
    margin-top: auto;
    margin-bottom: auto;
}

.hs_table_explainer_container div {
  width: 30%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background: var(--very-light-grey);
    border-radius: 10px;
    
    margin-left: 20%;
   
    margin-top: 10px;
    margin-bottom: 10px;
    

}

.hs_table_explainer_container div p {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 900;
  font-size: 20px;
}

.hs_table_explainer_container div select {
  background: white;
  border: 5px solid var(--fr_colour);
  outline: none;
  padding: 0 2px;
  font-size: 20px;
  font-family: inherit;
  color: inherit;
  width: 200px;
  height: 40px;
  margin-bottom: 20px;
}

/*simple harmonic motion wobble animation */
@keyframes wobble {
  0% {
      transform: translateX(0.00px);
  }
  1% {
      transform: translateX(2.81px);
  }
  2% {
      transform: translateX(5.58px);
  }
  3% {
      transform: translateX(8.29px);
  }
  4% {
      transform: translateX(10.90px);
  }
  5% {
      transform: translateX(13.39px);
  }
  6% {
      transform: translateX(15.75px);
  }
  7% {
      transform: translateX(17.96px);
  }
  8% {
      transform: translateX(19.99px);
  }
  9% {
      transform: translateX(21.83px);
  }
  10% {
      transform: translateX(23.46px);
  }
  11% {
      transform: translateX(24.88px);
  }
  12% {
      transform: translateX(26.06px);
  }
  13% {
      transform: translateX(27.00px);
  }
  14% {
      transform: translateX(27.70px);
  }
  15% {
      transform: translateX(28.15px);
  }
  16% {
      transform: translateX(28.34px);
  }
  17% {
      transform: translateX(28.29px);
  }
  18% {
      transform: translateX(27.98px);
  }
  19% {
      transform: translateX(27.42px);
  }
  20% {
      transform: translateX(26.63px);
  }
  21% {
      transform: translateX(25.61px);
  }
  22% {
      transform: translateX(24.36px);
  }
  23% {
      transform: translateX(22.91px);
  }
  24% {
      transform: translateX(21.27px);
  }
  25% {
      transform: translateX(19.45px);
  }
  26% {
      transform: translateX(17.47px);
  }
  27% {
      transform: translateX(15.34px);
  }
  28% {
      transform: translateX(13.10px);
  }
  29% {
      transform: translateX(10.76px);
  }
  30% {
      transform: translateX(8.34px);
  }
  31% {
      transform: translateX(5.87px);
  }
  32% {
      transform: translateX(3.36px);
  }
  33% {
      transform: translateX(0.84px);
  }
  34% {
      transform: translateX(-1.67px);
  }
  35% {
      transform: translateX(-4.15px);
  }
  36% {
      transform: translateX(-6.57px);
  }
  37% {
      transform: translateX(-8.91px);
  }
  38% {
      transform: translateX(-11.16px);
  }
  39% {
      transform: translateX(-13.29px);
  }
  40% {
      transform: translateX(-15.28px);
  }
  41% {
      transform: translateX(-17.13px);
  }
  42% {
      transform: translateX(-18.81px);
  }
  43% {
      transform: translateX(-20.31px);
  }
  44% {
      transform: translateX(-21.61px);
  }
  45% {
      transform: translateX(-22.72px);
  }
  46% {
      transform: translateX(-23.62px);
  }
  47% {
      transform: translateX(-24.30px);
  }
  48% {
      transform: translateX(-24.75px);
  }
  49% {
      transform: translateX(-24.99px);
  }
  50% {
      transform: translateX(-25.00px);
  }
  51% {
      transform: translateX(-24.79px);
  }
  52% {
      transform: translateX(-24.36px);
  }
  53% {
      transform: translateX(-23.72px);
  }
  54% {
      transform: translateX(-22.87px);
  }
  55% {
      transform: translateX(-21.83px);
  }
  56% {
      transform: translateX(-20.60px);
  }
  57% {
      transform: translateX(-19.20px);
  }
  58% {
      transform: translateX(-17.64px);
  }
  59% {
      transform: translateX(-15.94px);
  }
  60% {
      transform: translateX(-14.11px);
  }
  61% {
      transform: translateX(-12.17px);
  }
  62% {
      transform: translateX(-10.13px);
  }
  63% {
      transform: translateX(-8.03px);
  }
  64% {
      transform: translateX(-5.87px);
  }
  65% {
      transform: translateX(-3.68px);
  }
  66% {
      transform: translateX(-1.47px);
  }
  67% {
      transform: translateX(0.73px);
  }
  68% {
      transform: translateX(2.91px);
  }
  69% {
      transform: translateX(5.04px);
  }
  70% {
      transform: translateX(7.11px);
  }
  71% {
      transform: translateX(9.09px);
  }
  72% {
      transform: translateX(10.98px);
  }
  73% {
      transform: translateX(12.76px);
  }
  74% {
      transform: translateX(14.41px);
  }
  75% {
      transform: translateX(15.91px);
  }
  76% {
      transform: translateX(17.26px);
  }
  77% {
      transform: translateX(18.44px);
  }
  78% {
      transform: translateX(19.45px);
  }
  79% {
      transform: translateX(20.28px);
  }
  80% {
      transform: translateX(20.92px);
  }
  81% {
      transform: translateX(21.37px);
  }
  82% {
      transform: translateX(21.63px);
  }
  83% {
      transform: translateX(21.69px);
  }
  84% {
      transform: translateX(21.56px);
  }
  85% {
      transform: translateX(21.24px);
  }
  86% {
      transform: translateX(20.73px);
  }
  87% {
      transform: translateX(20.04px);
  }
  88% {
      transform: translateX(19.18px);
  }
  89% {
      transform: translateX(18.16px);
  }
  90% {
      transform: translateX(16.99px);
  }
  91% {
      transform: translateX(15.68px);
  }
  92% {
      transform: translateX(14.24px);
  }
  93% {
      transform: translateX(12.69px);
  }
  94% {
      transform: translateX(11.04px);
  }
  95% {
      transform: translateX(9.31px);
  }
  96% {
      transform: translateX(7.51px);
  }
  97% {
      transform: translateX(5.66px);
  }
  98% {
      transform: translateX(3.79px);
  }
  99% {
      transform: translateX(1.89px);
  }
  100% {
      transform: translateX(0.00px);
  }
}

.first_mock_up {
  width: 100%;
  display: flex;
  flex-direction: column;
  --pad : 20px;
}

.first_mock_up > div {
  padding: var(--pad);
  width: calc(100% - (var(--pad) * 2));
  height: 100%;
}

.first_mock_up .pr_box {
  flex: 1;
  background: var(--main-color);
  padding-bottom: calc(var(--pad) + 90px);
  border-radius: 10px;
}





.first_mock_up h1, .first_mock_up p {
  text-align: center;
  width: 100%;
  color: black;
}

.first_mock_up .pr h1 {
  color: white;
}


.fr_detail h4 {
  font-size: 3rem
}
.first_mock_up .pr .part h1 {
  font-size: 3rem
}

.first_mock_up .pr {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.first_mock_up .pr_parts {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
}

.plus {
  height: 150%;
  width: 1%;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: -1.8%;
}

.plus2 {
  height: 120%;
  width: 1%;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 0.2%;
}

.input_box h2, .input_box h3 {
  font-size: 1.25rem
}

.first_mock_up .part {
  width: 100%;
  height: 100%;
  padding: 10px;
  position: relative;
  background: var(--col);
  border-radius: 10px;
  color: white;
  transition: transform 0.3s linear;
}

.part .part_ls h2:nth-of-type(1) {
  position: absolute;
  height: 20%;
  top: 50%;
  right: calc(50% - 125px);
  width: 200px;
  text-align: center;
}

.first_mock_up .pr_parts .part {
  transform: translateY(30px)
}

/*
.first_mock_up .part:hover {
  transform: scale(1.05);
  box-shadow: 5px 5px 5px 5px var(--lighter);
}
*/
/*
.first_mock_up .pr_parts .part:nth-child(2) {
  transform: translateY(30px)
}


.first_mock_up .pr_parts .part:nth-child(2):hover {
  transform: translateY(30px) scale(1.05)
}


.first_mock_up .pr_parts .part:nth-child(3) {
  transform: translateY(60px)
}


.first_mock_up  .part:nth-child(3):hover {
  transform: translateY(60px) scale(1.05)
}
*/

.first_mock_up  .part:not(:first-child) {
 /* border-left: 2px solid black; */
 margin-left: 30px;
}

/*
.first_mock_up .part .part_ls h2 {
  transition: transform 0.3s linear
}

.first_mock_up .part:hover .part_ls h2 {
  transform: scale(1.5)
}

.first_mock_up .part .part_ls img {
  transition: transform 0.3s linear
}

.first_mock_up .part:hover .part_ls img {
  transform: translateX(100px) rotate(360deg) 
}
*/


.first_mock_up .part_ls {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 30%;
  justify-content: center;
  align-items: center;
}


.first_mock_up .part_ls2 {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 65%;
  justify-content: center;
  align-items: center;
}

.first_mock_up .part:not(:first-child) .part_ls {
  margin-top: 20px
}

.first_mock_up .part_ls img {
  margin-left: 30px
}

.first_mock_up .part_ls h2 {
  margin-right: 30px
}

.first_mock_up button {
  height: calc(var(--pad) * 2);
  width: 150px;
  position: absolute;
  top: calc(95% - var(--pad) * 2);
  left: calc(50% - 75px);
  border-radius: 5px;
  background: white;
  border: 2px solid black;
  color: black;
  font-weight: 700;
  transition: all 0.3s linear
}

.first_mock_up button:hover {
  border: 2px solid var(--main-color);
  color: var(--main-color);
  transform: scale(1.05);
  cursor: pointer;
}

.first_mock_up .part:not(:first-child) button {
  top: calc(95% - var(--pad) * 2 );
}

.first_mock_up .fr_box {
  flex: 2;
  margin-top: 20px;
  padding: 20px;
  background: var(--very-light-grey);
  border-radius: 10px;
}

.first_mock_up .fr {
  width: 100%;
  height: 100%;
}

.first_mock_up .fr > div {
  width: 100%
}

.first_mock_up .fr_title {
  height: 20%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: black;
}

.first_mock_up .fr_reformed, .first_mock_up .fr_legacy {
  height: 40%;
  display: flex; 
  flex-direction: row
}

.first_mock_up .if_choose {
  flex: 1;
  color: black;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 10px;
}

.first_mock_up .fr_detail {
  flex: 2;
}

.first_mock_up .fr_detail .part {
  background: var(--col);
  width: calc(100% - var(--pad) * 3);
  margin: 20px;
  height: 80%
}

.lump_sum_help_button {
  position: absolute;
  left: calc(100% + 10px);
  top: 15px;
  color: black;
  background: white;
  border-radius: 10px;
  border: 2px solid black;
  transition: transform 0.1s linear;
  width: 200px;
  height: 40px;
  font-weight: 500;
  font-size: medium;
}

.lump_sum_help_button:hover {
  color: white;
  border: none;
  transform: scale(1.03);
  cursor: pointer;
  background: var(--main-color);
  border-color: var(--main-color)
}
