.background {
  position: fixed;
  z-index: 5;
  background-color: rgba(0, 0, 0, 0.75);
  width: 800vw;
  height: 1000vh;
  top: 0;
  left: 0;
}

.modal {
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
  border-radius: 6px;
  background-color: rgb(255, 255, 255);
  padding: 1.2rem 2.2rem;
  text-align: center;
  width: 80%;
  position: fixed;
  z-index: 10;
  top: 10vh;
  left: 10%;
  height: 80vh
}

.modal p {
  text-align: justify;
}

.btn_main {
  font: inherit;
  padding: 0.5rem 1.5rem;
  border-radius: 4px;
  font-weight: 500;
  cursor: pointer;
  font-size: 1.25rem;
  background-color: var(--main-color);
  color: rgb(255, 255, 255);
  border: 1px solid var(--main-color);
  font-size: 1.25rem;
}

.btn_main:hover {
  background-color: var(--main-color-light);
  border-color: var(--main-color-light);
}
