.date_container {
    height: 40%;
    align-items: center;
    display: flex;
}

.overall_container {
    width: 100%;
    height: calc(100% - 0.1px);
    padding-top: 0.1px /* to prevent margin collapse*/
}

.overall_container h3 {
    height: 10%;
    margin-top: 20px;
    text-align:center;
    margin-bottom: 0
}

.explain_container {
    display: flex;
    align-items: center;
    justify-content: center
}

.explain_container button {
    background: var(--main-color);
    color: white;
    font-size: 1.25rem;
    border-radius: 10px;
    padding: 0.5rem 1.5rem;
    border: none;
    height: 60px;
    font-weight: 700;
    border-radius: 10px
}

.explain_container button:hover {
    cursor: pointer;
    background: var(--main-color-light)
}

.answer_container {
    display: flex;
    width: 100%;
    justify-content: center;
    margin-top: 5%
}

p.error {
    width: 80%;
    margin-left: 10% !important;
    text-align: center;
    color: red;
    margin-top: 50px !important;
}