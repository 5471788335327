

.main_container {
    height: 100%
}

.first_question_container {
    display: flex;
    height: 15%;
    transition: transform 0.3s linear
}

.first_question_container h2 {
    width: 40%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 10%;
}

.options_container {
    width: 50%;
    display: flex;
    justify-content: space-evenly;
    align-items: center
}

.yes_no_container {
    width: 30%;
    display: flex;
    justify-content: space-around;
}

.yes_no_container button {
    width: 100%;
    margin-left: 2%;
    margin-right: 2%;
    border-radius: 10px;
    font-size: 1.3rem;
    min-height: 50px;
    max-height: 50px;
    font-weight: 700;
    transition: all 0.2s linear;
}

.yes_no_container button.unpressed:hover {
    background: var(--full-legacy-light);
    border: 1.5px solid var(--full-legacy);
    color: var(--full-legacy);
    cursor: pointer;
    font-weight: 700;
    transform: translateX(0) translateY(0);
    
}

 .yes_no_container button.pressed {
    background: var(--main-color-light);
    border: 1.5px solid var(--main-coor);
    color: var(--main-color);
}

 .yes_no_container button.unpressed {
    background: var(--light-grey);
    border: 1.5px solid black;
    color: black;
    font-weight: 700;
 }

.explain_container {
    display: flex;
    justify-content: end;
    width: 50%;
    margin-right: 20%
}

.explain_container button {
    background: var(--main-color);
    color: white;
    font-size: 1.25rem;
    border-radius: 10px;
    padding: 0.5rem 1.5rem;
    border: none;
    height: 50px;
    font-weight: 700;
    border-radius: 10px
}

.explain_container button:hover {
    cursor: pointer;
    background: var(--main-color-light)
}

.part_time_section {
    transition: all 0.3s cubic-bezier(0.98, 0.02, 0.795, 0.035);
    position: absolute;
}

.part_time_section_container {
    position: relative;
    height: 85%
}