.progress_indicator_container {
  max-height: 600px;
  transition: all 0.3s linear
}

.progress_indicator_container.invisible {
  opacity: 0;
  max-height: 0;
  overflow: hidden;
  margin: 0;
  padding: 0
}

.progress_indicator_button {
  max-height: 100px;
  transition: all 0.3s linear;
  background: var(--main-color);
  color: white;
  font-size: 1.25rem;
  border-radius: 10px;
  padding: 0.5rem 1.5rem;
  border: none;
  height: 60px;
  font-weight: 700;
  border-radius: 10px;
  width: 40%;
  transition: all 0.2s linear;
  margin-left: 30%;
}

.progress_indicator_button:hover {
  cursor: pointer;
  background: var(--main-color-light);
}

.progress_indicator_button.invisible {
  opacity: 0;
  max-height: 0;
  overflow: hidden;
  margin: 0;
  padding: 0
}