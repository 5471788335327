
.main_container {
    height: 90%; width: 100%
}

.inner_container {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 80%;
    margin-top: 2%;
    margin-bottom: 2%;
}

.first_container, .second_container {
    margin-left: 2.5%;
    margin-right: 2.5%;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 80%;
    overflow: auto
}

.first_container {
    width: 40%
}

.second_container {
    width: 50%
}

.main_container h1 {
    width: 100%;
    text-align: center
}

.main_container table {
    width: 100%;
    table-layout: fixed; /* Key property for equal column widths */
}

.main_container th, .main_container td {
    text-align: center; /* Optional: Align text to left */
    border: 2px solid var(--main-color); /* Optional: add borders to cells */
    padding: 8px; /* Optional: padding inside cells */
}

.main_container tr th {
    background : var(--main-color);
    color: white;
}
